import { useState } from 'react'
import Image from 'next/image'
import { Dialog } from '@headlessui/react'
import { extraNavigation, mainNavigation } from './data'
import Menu from './Menu'
import MenuItem from './MenuItem'
import MenuItemTransition from './MenuItemTransition'
import { SidebarContext } from './SidebarContext'
import ToggleButton from './ToggleButton'

export type MobileNavProps = {
  hideNavigation?: boolean
  title?: string
}

export default function SidebarMobile({ title, hideNavigation }: MobileNavProps) {
  const [open, setOpen] = useState(false)
  const logoutItem = extraNavigation.find((item) => item.name?.toLowerCase() === 'logout')

  return (
    <SidebarContext.Provider
      value={{
        isOpen: open,
        isClosed: !open,
        openSidebar: () => setOpen(true),
        closeSidebar: () => setOpen(false),
      }}
    >
      <Dialog open={open} onClose={setOpen} className="relative z-50 md:hidden">
        <Dialog.Backdrop className="fixed inset-0 bg-gray-900/80 transition-opacity duration-200 ease-linear data-[closed]:opacity-0" />

        <div className="fixed inset-0 flex">
          <Dialog.Panel className="relative flex w-full flex-1 transform transition duration-200 ease-in-out data-[closed]:-translate-x-full">
            {/* Sidebar */}
            <div className="bg-deep-teal-500 flex grow flex-col gap-y-5 overflow-y-auto px-6 py-6">
              <div className="flex items-center gap-5 px-1">
                <ToggleButton onClick={() => setOpen(false)} />

                <MenuItemTransition show={open}>
                  <Image src="/images/meetperry-white.svg" height={24} width={121} alt="" />
                </MenuItemTransition>
              </div>

              {hideNavigation ? (
                <div className="flex-1" />
              ) : (
                <Menu className="mt-14 flex-1" items={mainNavigation} />
              )}

              <hr className="my-2 border-white/40" />

              {!hideNavigation && <Menu items={extraNavigation} />}

              {hideNavigation && logoutItem && (
                <MenuItem name={logoutItem.name} href={logoutItem.href} icon={logoutItem.icon} />
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Top navbar */}
      <div className="bg-deep-teal-500 sticky top-0 z-40 flex h-16 shrink-0 items-center gap-5 px-6 md:hidden">
        <ToggleButton onClick={() => setOpen(true)} />
        <p className="text-lg/6 tracking-[0.01em] text-white">{title}</p>
      </div>
    </SidebarContext.Provider>
  )
}
