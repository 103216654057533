import { ComponentPropsWithoutRef, FC } from 'react'
import { LDFlag, useFlag } from 'launchdarkly'
import { MainLayout } from './MainLayout'
import SidebarLayout from './SidebarLayout'

export type DefaultLayoutProps = ComponentPropsWithoutRef<'div'> & {
  title?: string
  hideToolbar?: boolean
  Toolbar?: FC
  banner?: JSX.Element
  hideAnnouncementBanner?: boolean
}

export function DefaultLayout(props: DefaultLayoutProps) {
  const displayNewSidebar = useFlag(LDFlag.DisplayNewSidebar)
  const hideNavInSidebar = !!props?.Toolbar || props?.hideToolbar

  if (displayNewSidebar) {
    return <SidebarLayout hideNavigation={hideNavInSidebar} {...props} />
  }

  return <MainLayout {...props} />
}
